$lightBgColor: #fff;
$lightestGrey: #f6f6f6;
$primaryTextColor: #000;
$primaryColor: #1abc9c;
$lightPrimaryColor: #d6e5fe;
$lightTextColor: #fff;
$colorSuccess: #1db618;
$colorDanger: #cf241f;
$colorLightDanger: #fce8e6;
$lightRed: #fce8e6;
$greyHover: #ededed;
$grey: #d3d3d3;
$lightGrey: #eee;
$lighterGrey: #f9f9f9;
$darkGrey: #838383;
$darkestGrey: #666666;
$lightYellow: rgb(255, 251, 211);
$yellow: #ffb600;
$lightGreen: rgb(231, 255, 209);
$green: #06b73d;
$lightPink: rgb(255, 229, 226);
$pink: #ff024e;
$lightBlue: rgb(214, 236, 255);
$blue: #0076fa;
$primary: #3dc7ae;
