@import "src/styles/variables.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 0.875rem;
}

h2 {
  font-weight: bold;
  font-size: 1rem;
}

h1 {
  font-weight: bold;
  font-size: 1.25rem;
}

ul {
  list-style-position: inside;

  li {
    list-style-type: disc;
    text-align: left;
    margin-left: 1em;
  }
}

body .zls-sptwndw {
  z-index: 0 !important;
}
body .zsiq_floatmain {
  z-index: 0 !important;
}

.zsiq_floatmain {
  position: absolute;
  top: calc(100vh - 130px);
  left: 13px;
  scale: 0.8;
}

.unordered-list {
  list-style-position: outside;

  li {
    list-style-type: disc;
    text-align: left;
    margin-left: 1em;
  }
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

ol {
  display: block;
  list-style-type: decimal;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

dd,
dl,
dt,
li,
ol,
ul {
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.paragraph-terms {
  span {
    margin: 0 5px 0 0;
  }
}

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.4);
  opacity: 1; /* Firefox */
}

.order_item_default {
  background-image: url("https://stage.uafrica.com/img/public/no_image_45.png");
  width: 45px;
  height: 45px;
}

.text-yellow-link {
  color: rgba(38, 38, 38, 0.9);

  &:hover {
    color: rgba(38, 38, 38, 1);
    cursor: pointer;
  }
}

.announcement_image {
  max-width: 800px;
  width: auto;
  height: auto;
  max-height: 400px;
}

.border-danger-2 {
  border: 2px $colorDanger solid;
}

.text-primary-600 {
  color: #1abc9c;
}

.text-primary-710 {
  color: #1abc9c;
}

.border-primary-700 {
  border: 1px solid #1abc9c;
}

.logo-80 {
  height: 80px;
  width: auto;
}

.logo-35 {
  height: 35px;
  width: auto;
}

.logo-30 {
  height: 30px;
  width: auto;
}

.logo-bobgo-30 {
  height: 30px;
  width: auto;
  margin-right: -0.25rem;
  margin-left: 0.5rem;
}

.-mt-18 {
  margin-top: -5rem;
}

.-mt-5 {
  margin-left: -5px;
}

#markup_id {
  height: 230px;
}

#textarea_height {
  height: 340px;
}

.text-muted {
  color: $darkGrey;
}

.pl-14 {
  padding-left: 4.25rem;
}

.w-14 {
  width: 14rem;
}

.w-70 {
  width: 70px;
}

.w-50 {
  width: 50px;
}

.w-75 {
  width: 75px;
}

//.w-80 {
//  width: 80px;
//}

.w-95 {
  width: 95px;
}

.w-100 {
  width: 100px;
}

.w-150 {
  width: 150px;
}

.w-200 {
  width: 200px;
}

.w-250 {
  width: 250px;
}

/* HELPERS */
.mt-15 {
  margin-top: 4rem;
}

.-mt-51 {
  margin-top: -51px;
}

.min-h-7 {
  min-height: 1.8rem;
}

.min-w-7 {
  min-width: 7rem;
}

.min-h-9 {
  min-height: 2.25rem;
}

.-ml-075 {
  margin-left: -0.175rem;
}

.top-175 {
  top: 0.45rem;
}

.w-45 {
  width: 1.125rem;
}

.max-w-550 {
  max-width: 550px;
}

.max-w-xl {
  max-width: 48rem;
}

#modal-host
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(2)
  > div
  > div:nth-child(1)
  > div
  > div
  > div
  > div.flex.flex-row {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .max-w-xxs {
    max-width: 18rem;
  }
}

.inline-error {
  display: inline-block;
  color: $colorDanger;
}

.pl-46 {
  padding-left: 11.5rem;
}

.pl-25p {
  padding-left: 25%;
}

.ml-25p {
  margin-left: 25%;
}

.pl-17 {
  padding-left: 4.25rem;
}

.pl-13 {
  padding-left: 3.25rem;
}

.max-h-550 {
  max-height: 550px;
}

.h-63v {
  height: 63vh;
}

.h-65v {
  height: 65vh;
}

.smaller {
  font-size: smaller;
}

.small {
  font-size: small;
}

.default-cursor-pointer {
  cursor: default !important;
}

.overflow-break {
  overflow-wrap: break-word;
}

#border-top {
  border-top: 1px solid rgba(229, 229, 229, 0.5);
}

.dropdown {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.link {
  cursor: pointer;
  color: $primaryColor;
}

#api-log-account-name {
  padding: 0 0 0 0.25rem !important;
  height: 0;
}

.link:hover {
  color: darken($primaryColor, 20%);
}

.h-fit-content {
  height: fit-content;
}

.w-fit-content {
  width: fit-content;
}

.font-size-xxl {
  font-size: xx-large;
}

.mt-5px {
  margin-top: 5px;
}

.min-w-8 {
  min-width: 8rem;
}

.min-w-18 {
  min-width: 18rem;
}

.min-h-35 {
  min-height: 35rem;
}

.min-w-6 {
  min-width: 6rem;
}

.align-text-center {
  text-align: center;
}

.align-text-left {
  text-align: left;
}

.restricted {
  float: left;
  margin-left: 20px;
  margin-top: 20px;
}

.loader {
  border-top-color: #1abc9c !important;
}

.overflow-anywhere {
  overflow-wrap: anywhere;
}

#border-bottom {
  border-bottom: 1px solid #e5e5e5;
}

#border-top {
  border-top: 1px solid #e5e5e5;
}

.form-group {
  margin-bottom: 5px !important;
}

.duplicate-error {
  border: 1px solid $colorDanger;
  padding: 5px;

  .error {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

.banner-red {
  @apply bg-red-100 p-4 flex justify-between items-center text-red font-bold sticky top-0 z-10 -mt-4 mb-4 -mx-4 z-50;
}

.label-right {
  @apply font-semibold mr-4 text-right text-gray-900 w-48;
}

.label-standard {
  @apply font-semibold mr-4 text-gray-900;
}

.responsive-container {
  @apply flex flex-col md:flex-row space-x-0 md:space-x-4;
}

.responsive-item-col-2 {
  @apply w-full md:w-1/2;
}

.responsive-item-col-3 {
  @apply w-full md:w-1/3;
}

.responsive-item-col-4 {
  @apply w-full md:w-1/4;
}

.responsive-col-2 {
  @apply grid gap-5 sm:grid-cols-1 md:grid-cols-2;
}

//.responsive-col-3 {
//  @apply grid gap-5 sm:grid-cols-1 md:grid-cols-3
//}

.responsive-col-4 {
  @apply grid gap-5 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4;
}

.channel-install-logo {
  @apply rounded-full h-32 w-32 mx-auto flex items-center justify-center;
}

.channel-details-logo {
  @apply rounded-full h-14 w-14 mx-auto flex items-center justify-center border-4 shadow-lg shadow-inner;
}

.channel-install-option {
  @apply p-6 flex flex-col items-center justify-center cursor-pointer;
}

.channel-installer {
  padding: 1.25rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  & img {
    transform: scale(1);
    transition: all 0.2s ease-in-out;
  }

  &:hover img {
    transform: scale(1.1);
  }
}

.image-grow-animate {
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    scale: 1.1;
  }
}

// Media query ONLY for order index

.onboarding {
  height: 70vh;
  display: flex;
  align-items: center;
  width: 60vw;
  max-width: 800px;
  margin: auto;
}

.pod-parent {
  height: 150px;
  width: 150px;
  background: rgba(196, 196, 196, 0.3);
  border-radius: 0.25rem;

  & .pod-container {
    display: block;
    width: auto;
    height: 100%;
    position: relative;
    overflow: hidden;
    //padding: 34.37% 0 0 0;

    & .pod-image {
      display: block;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}

/* MAP */
.map-toolbar {
  position: absolute;
  top: 35px;
  left: 35px;
  z-index: 1;

  .select {
    width: 250px !important;
    display: inline-block;
  }

  .btn {
    border-radius: 5px;
    color: black !important;
    background: white;
    border-color: $lightGrey;
    margin-left: 10px;

    &.active {
      background: white;
      border: 3px solid $primaryColor;
      color: black;
    }

    &:hover {
      svg {
        color: $primaryColor !important;
      }
    }

    svg {
      margin-right: 0;
    }
  }
}

.manual-fields {
  display: flex;
  justify-items: end;
  margin-bottom: 0.5rem;
  margin-left: 1.25rem;
  width: 27rem;
}

@media screen and (max-width: 640px) {
  .onboarding {
    height: 70vh;
    display: flex;
    align-items: center;
    width: 90vw;
    max-width: 800px;
    margin: auto;
  }

  .manual-fields {
    display: flex;
    justify-items: end;
    margin-bottom: 0.5rem;
    margin-top: 1.25rem;
    margin-left: 0rem;
    width: 20rem;
  }
}

.onboarding-completed {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;

  & > span {
    text-align: center;
    margin: 0 auto;
    width: 50%;
  }
  & > img {
    margin: 0 auto;
  }
}

.account-onboarding {
  width: 67%;
  margin: 0 auto;
  min-width: 540px;

  .account-onboarding-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    img {
      margin: 0 auto;
    }

    span {
      text-align: center;
    }
  }
}

@media screen and (min-width: 769px) {
  .onboarding-header {
    display: flex;
    justify-content: space-around;
    gap: 1.25rem;

    .onboarding-item {
      width: 25%;

      .progress-bar {
        height: 2px;
        position: relative;
        background-color: lightgrey;
        --tw-border-opacity: 1;
        border-color: rgba(212, 212, 212, var(--tw-border-opacity));
        border-bottom-width: 3px;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      .progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $primaryColor;
        animation: progress 5s linear forwards;
      }

      @keyframes progress {
        0% { width: 0; }
        100% { width: 100%; }
      }

      & div:first-child {
        margin-bottom: 0.75rem;
      }
    }
  }

  .onboarding-save-panel {
    border-top: 5px solid $primaryColor;
    margin-left: -19px;
  }

  .onboarding-layover {
    border-top: 5px solid $primaryColor;
    border-left: 5px solid $primaryColor;
    border-right: 5px solid $primaryColor;
    margin-bottom: 71.2px;
  }

  .custom-save-panel {
    margin-top: 5rem;
  }
}


@media screen and (max-width: 768px) {
  .onboarding-header {
    display: flex;
    gap: 1.25rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    overflow-x: scroll;

    .onboarding-item {
      min-width: 100px;

      .progress-bar {
        height: 2px;
        position: relative;
        background-color: lightgrey;
        --tw-border-opacity: 1;
        border-color: rgba(212, 212, 212, var(--tw-border-opacity));
        border-bottom-width: 3px;
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      .progress {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: $primaryColor;
        animation: progress 5s linear forwards;
      }

      @keyframes progress {
        0% { width: 0; }
        100% { width: 100%; }
      }
    }
  }

  .account-onboarding {
    width: 100%;
    min-width: 0;
  }

  .onboarding-save-panel {
    border-top: 5px solid $primaryColor;
    margin-left: 0px;
  }

  .onboarding-layover {
    border-top: 5px solid $primaryColor;
    border-left: 5px solid $primaryColor;
    border-right: 5px solid $primaryColor;
    margin-bottom: 131.4px;
  }

  .custom-save-panel {
    margin-top: 9rem;
  }
}

.register-container {
  margin: auto auto;
}

.order-index {
  display: flex;
  flex-direction: row;
  padding-top: 2px;
  gap: 2rem;
  justify-content: space-between;

  & .order-info {
    width: 27%;
  }

  & .order-ship-to {
    width: 30%;
  }

  & .fulfillment-details {
    width: 43%;
  }
}

.width-150 {
  width: 150px;
}

.min-width-150 {
  min-width: 140px;
}

.width-110 {
  min-width: 90px;
}

@media screen and (max-width: 640px) {
  .order-index {
    display: flex;
    padding-top: 2px;
    flex-direction: column;
    gap: 2rem;
    justify-content: space-between;

    & .order-info {
      width: 100%;
    }

    & .order-ship-to {
      width: 100%;
    }

    & .fulfillment-details {
      width: 100%;
    }
  }
}

.delete {
  color: $colorDanger !important;
  cursor: pointer;

  &:hover {
    color: lighten($colorDanger, 20%) !important;
  }
}

.duplicate {
  color: $primaryTextColor !important;
  cursor: pointer;

  &:hover {
    color: lighten($primaryTextColor, 40%) !important;
  }
}

.edit-pencil {
  color: $primary !important;
  cursor: pointer;

  &:hover {
    color: lighten($primary, 20%) !important;
  }
}

.mark-as-read-envelope {
  color: $primary !important;
  cursor: pointer;

  &:hover {
    color: darken($primary, 20%) !important;
  }
}

.-mt-7 {
  margin-top: -1.75rem;
}

.component-image {
  height: 150px;
  width: auto;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 1rem;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 1rem;
    box-shadow: 0 5px 7px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
  }
}

.table-col {
  @apply px-2 py-2 whitespace-nowrap text-gray-900;
}

.table-image {
  height: 40px;
  width: 40px;
  border-radius: 0.25rem;
}

.left-add-btn {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(182, 234, 225, 0.5);
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;

  &:hover {
    cursor: pointer;
    background-color: rgba(182, 234, 225, 1);
  }
}

.right-add-btn {
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(182, 234, 225, 0.5);
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;

  &:hover {
    cursor: pointer;
    background-color: rgba(182, 234, 225, 1);
  }
}

.inline-modal,
.map-panel {
  padding: 20px;
  z-index: 1;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;

  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15) !important;
  -webkit-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15) !important;
}

.radio-label {
  margin-left: 15px;
  display: inline-block;
  font-weight: bold;
}

.strike {
  text-decoration: line-through !important;
}

.no-strike {
  text-decoration: none !important;
}

.actions {
  color: $primaryTextColor !important;
}

.toasts {
  position: absolute;
  z-index: 1080;
  top: 0px;
  right: 10px;
  padding: 1px 11px 0;
}

.parcel:not(:last-child) {
  border-bottom: 1px solid $grey;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.or {
  text-align: center;
  font-weight: bold;
}

.icon-button {
  margin-right: 10px;
  color: $primaryColor !important;
  cursor: pointer;

  &:hover {
    color: lighten($primaryColor, 20%) !important;
  }
}

.delete {
  color: $colorDanger !important;
  cursor: pointer;

  &.right {
    margin-right: 8px;
  }

  &:hover {
    color: lighten($colorDanger, 20%) !important;
  }
}

.alert {
  margin: 10px 0px;

  p {
    margin: 0;
  }

  svg {
    margin-right: 8px;
  }
}

.alert-danger {
  h2 {
    color: #721c24;
  }
}

.alert-light {
  padding: 0;
}

.cancel svg {
  margin-left: 10px;
}

.cancel:hover svg {
  color: $colorDanger !important;
}

/* ORDER LOGS */
.order-logs {
  & .logs-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  & .timeline-list {
    list-style-type: none;
    padding: 0;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 2px;
      background-color: #e5e5e5;
      top: 8px;
      bottom: 0;
      left: 40px;
      margin-left: -3px;
    }
  }

  & .timeline-item {
    display: flex;
    padding: 7px 0;
  }

  & .checkpoint-item {
    border-radius: 20px;
    background: $lightYellow;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  & .checkpoint-container {
    background-color: transparent;
    width: 87%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    padding: 5px 7px;
  }

  & .checkpoint-dates {
    display: flex;
    color: #8f8f8f;
    font-size: 13px;
  }

  & .checkpoint-content {
    & p {
      margin: 0;
    }
  }

  & .day-display {
    display: flex;
    color: #8f8f8f;
    font-size: 13px;

    & p {
      margin: 0 0 0 5px;
    }
  }

  & .checkpoint-spacing {
    padding: 0px 5px;
    display: flex;
    justify-content: center;
    width: 50px;
  }

  & .order-log-user {
    margin-left: 3px;
  }

  & .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  & .no-logs-alert {
    margin-top: 1.5rem;
  }

  & .stop-fetching-text {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    & div {
      border-radius: 50%;
      border: 1px solid #3aaf46;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px;
    }

    & span:nth-child(2) {
      margin-top: 7px;
    }

    & span:last-child {
      color: #6c757d;
    }
  }
}

/* RATES */
.rate-card {
  &:not(:first-child) {
    margin-top: 2rem;
  }
}

/* INVOICE UPLOADER */
.droppable {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  height: 200px;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }

  & .active {
    border-color: #2196f3;
  }

  & .accept {
    border-color: #00e676;
  }

  & .reject {
    border-color: #ff1744;
  }
}

/* PAGINATION */
.page-info {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}

.page-jump {
  display: inline-block;
  margin-right: 5px;

  & input {
    width: 70px;
    text-align: center;
    border-radius: 0.2rem;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.total-pages {
  box-sizing: border-box;
  margin-left: 5px;
}

/* ICONS */

.heading-icon-small {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  padding: 3px 0;
  text-align: center;
  margin-right: 15px;
  margin-bottom: 10px;
  display: inline-block;
}

.key-icon {
  border: 1px solid $lightYellow;
  background: $lightYellow;
  color: $yellow;
}

.logo-settings {
  max-width: 200px;
}

.company-logo {
  margin-top: 20px;
  margin-bottom: 20px;
}

.filters-dropdown {
  display: inline-block;
  margin-right: 15px;

  .btn {
    color: $primaryTextColor;
    border: 1px solid $grey;
    border-radius: 4px;
    padding: 7px 8px;
    background: #fff;
    margin-left: 0px;
    margin-right: 0 !important;
  }
}

.green-check {
  color: $green;
  font-weight: bold;
}

a:hover .cancel svg {
  color: $colorDanger !important;
}

/* IMPERSONATION */

.account-on-hold {
  margin-bottom: 44px;

  .account-on-hold-container {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    background: lighten($colorDanger, 40%);
    padding: 10px 35px;

    svg {
      color: $colorDanger !important;
    }

    .account-on-hold-text {
      font-weight: bold;
      margin-left: 10px;
      color: $colorDanger !important;
    }

    a {
      float: right;
      color: $colorDanger !important;
      text-decoration: underline !important;
    }
  }

  &.inline {
    margin-bottom: 0;
    width: 100%;

    .account-on-hold-container {
      position: relative;
    }
  }
}

/* TABLES */

.custom-table {
  .custom-table-head {
    background-color: $primaryColor;
  }

  .custom-table-th {
    background-color: #d1f1eb;
  }

  .row {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-size: 13px;
    padding: 10px 6px 7px;
  }
}

.checkbox-in-table {
  cursor: default;
  padding-left: 5px;
  input {
    position: relative;
    top: 3px;
  }
}

.checkbox-row {
  padding: 0 !important;
}

table {
  th {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 10px 3px 10px;
    color: #111827;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 12px !important;
    letter-spacing: 0.07em;
    background-color: #d1f1eb;
    border-bottom: 1px solid #3dc7ae;
    border-left: none !important;
    border-right: none !important;
  }
}

.ReactTable {
  border: 1px solid transparent !important;
  background: $lightBgColor;
  border-radius: 10px;
  box-shadow: 4px 1px 12px 2px #ececec;
  -webkit-box-shadow: 4px 1px 12px 2px #ececec;
  -moz-box-shadow: 4px 1px 12px 2px #ececec;

  &.no-shadows {
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
  }

  .rt-table {
    border-radius: 10px !important;
    text-align: left;
  }

  .rt-thead .rt-tr {
    text-align: left !important;
    background-color: #d1f1eb;
  }

  .rt-draggable-container .ReactTable .rt-thead .rt-th {
    justify-content: left;
    padding-left: 8px;
    padding-top: 2px;
    background-color: #d1f1eb;
  }

  .rt-tbody .rt-td {
    border: none;
    cursor: pointer;
    font-size: 13px;
    padding: 8px 10px;

    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .rt-tbody .rt-tr-group {
    border-bottom: none !important;
    flex: 0;
  }

  .rt-thead .rt-th {
    padding: 10px 10px 3px 10px;
    color: #111827;
    font-weight: 300;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.07em;
    border-left: none !important;
    border-right: none !important;
  }

  .rt-thead.-header {
    box-shadow: none !important;
    border-bottom: 1px solid $lightGrey;
  }

  a {
    color: $primaryTextColor !important;
  }

  a.color {
    color: $primaryColor !important;
    font-weight: bold;
  }
}

.default-cursor-table {
  .rt-table .rt-tbody .rt-tr-group .rt-tr .rt-td {
    cursor: default !important;
  }
}

.form-control:focus {
  box-shadow: none;
  color: black !important;
}

.form-control[disabled] {
  background: $lightGrey !important;
  opacity: 0.65 !important;
  color: $darkestGrey;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  background-color: #fafafa;
  opacity: 1;
}

.ReactTable {
  .rt-table {
    .rt-thead {
      min-height: 40px !important;
    }
  }

  .-pagination-bottom,
  .pagination-bottom {
    border-top: 1px solid #eee;
  }

  .-pagination {
    border-top: none !important;
    padding-top: 2px !important;
    box-shadow: none !important;

    .-btn {
      color: $primaryColor;
      width: auto;
      background: none;
      margin: 10px;
      padding: 3px 10px;
      height: 30px;

      &:not([disabled]):hover {
        background: none !important;
        color: darken($primaryColor, 20%) !important;
      }
    }

    .-next .-btn {
      float: right;
    }
  }

  .rt-noData {
    padding: 5px !important;
    top: 57px;
  }
}

.saving-overlay {
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 2000;
}

/* MAP */
// autocomplete

.mouse-tooltip {
  z-index: 1;
}

.color-picker-button {
  width: 16px;
  height: 16px;
  cursor: pointer;
  position: relative;
  left: 3px;
  border-radius: 3px;
}

.color-picker-button-no-hover {
  width: 16px;
  height: 16px;
  position: relative;
  left: 3px;
  border-radius: 3px;
}

.w-26 {
  width: 20rem;
}

.color-picker {
  position: relative;
  left: -10px;
  top: 13px;
  margin-bottom: 30px;

  input {
    height: 30px !important;
    width: 90px !important;
  }
}

.marker-in-list {
  padding: 10px;

  &.link {
    cursor: pointer;
    display: block;
    color: $primaryTextColor !important;

    &:hover {
      background: $greyHover;
    }
  }

  img {
    width: 24px;
  }

  .marker-label {
    margin-left: 5px;
    position: relative;
    top: 1px;
    display: inline-block;
  }
}

/* TOOLTIPS */

.info-text {
  font-size: 10pt;
}

/* INFINITE SCROLL */
.infinite-scroll-component {
  &.has-items {
    border: 1px solid $lightGrey;
    border-radius: 5px;
  }
}

.infinite-scroll-component :nth-child(1) {
  border-top: 0;
}

.address-list-height {
  max-height: calc(100vh - 240px) !important;
}

/* API-LOGS */
.api-log-text-only {
  font-family: monospace;
  cursor: default;
  background-color: rgb(39, 40, 34);
  position: relative;
  padding: 10px;
  max-height: 700px;
  overflow-x: scroll;
  color: rgb(249, 248, 245);
}

/* STATUSES */
.status-badge {
  display: inline;
  border-radius: 15px;
  padding: 3px 10px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  font-size: 10px;

  &.block {
    font-weight: normal;
    display: block;
  }

  &.status-badge-large {
    font-size: 14px;
  }
}

.status-badge-small {
  //display: inline;
  border-radius: 15px;
  padding: 3px 10px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  font-size: 10px;

  &.block {
    font-weight: normal;
    display: block;
  }
}

.status-badge-section-heading {
  border-radius: 15px;
  padding: 0px 10px;
  font-weight: bold;
  overflow: hidden;
  white-space: nowrap;
  font-size: 10px;

  &.block {
    font-weight: normal;
    display: block;
  }
}

.bubble {
  font-weight: bold;
  color: #1abc9c;
  background-color: #d1f1eb;
  padding: 3px 10px;
  border-radius: 15px;
  height: auto;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    border: 1px solid rgba(51, 51, 51, 0.19);
  }

  &:before {
    content: "";
    position: absolute;
    border: 1px solid #333;
  }

  /*triangle side*/

  /*left*/
  &.left:after {
    border-color: transparent #d1f1eb;
    border-width: 4px 6px 4px 0;
    top: 35%;
    left: -5px;
  }

  &.left:before {
    border-color: transparent #d1f1eb;
    border-width: 4px 6px 4px 0;
    top: 35%;
    left: -5px;
  }
}

.inactive-status {
  color: black;
  background: $lightestGrey;
}

.green-status {
  color: $green;
  background: $lightGreen;
}

.blue-status {
  color: $blue;
  background: $lightBlue;
}

.red-status {
  color: $colorDanger;
  background: $colorLightDanger;
}

.pink-status {
  color: $pink;
  background: $lightPink;
}

.yellow-status {
  color: #ffb600;
  background-color: #fffbd3;
}

.gray-status {
  color: black;
  background-color: $lightGrey;
}

.black-status {
  color: $primaryTextColor;
  background-color: #e5e5e5;
}

.primary-status {
  color: #1abc9c;
  background-color: #d1f1eb;
}

.notes-badge {
  display: inline;
  border-radius: 15px;
  padding: 2px 10px;
  font-weight: normal;
  overflow: hidden;
  white-space: nowrap;
  color: white;

  &.block {
    font-weight: normal;
    display: block;
  }
}

.green-note {
  background-color: $green;
}

.fuschia-note {
  background-color: #f178b6;
}

.iris-note {
  background-color: #a5a6f6;
}

/* DROPZONE */
.dropzone {
  padding: 15px;
  background: white;
  border: 2px dashed $primary;
  color: $primary;
  cursor: pointer;
  border-radius: 10px;
  margin-bottom: 10px;
}

.show-for-small {
  display: none;
}

/* SCANNING */
.scan-type {
  font-weight: bold;
  font-size: 16px;
  margin: 46px auto 20px;
  cursor: pointer;
  color: $colorDanger;
}

.scan-type-activated {
  color: $green;
  cursor: default !important;
}

.parcel-item {
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px;
  margin-bottom: 5px;
}

.parcel-success {
  color: $green;
  border: 1px solid $lightGreen;
  background-color: $lightGreen;
  border-radius: 5px;
}

/* TRACKING EVENTS */
.tracking-event {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .tracking-event-line {
    width: 1px;
    height: 100%;
    background-color: $grey;
    margin-left: 14px;
    position: relative;
  }

  .map-marker-container {
    width: 30px;
    height: 30px;
    border-radius: 17px;
    align-items: center;
    justify-content: center;
    position: relative;

    .fa-map-marker,
    .fa-caret-down,
    .fa-caret-up {
      font-size: 11pt;
      position: relative;
      top: 4px;
      left: 10px;
    }
  }

  .blue-status-marker {
    background-color: $lightBlue;

    .fa-map-marker,
    .fa-caret-down,
    .fa-caret-up {
      color: $blue;
    }
  }

  .green-status-marker {
    background-color: $lightGreen;

    .fa-map-marker,
    .fa-caret-down,
    .fa-caret-up {
      color: $green;
    }
  }

  .red-status-marker {
    background-color: $colorLightDanger;

    .fa-map-marker,
    .fa-caret-down,
    .fa-caret-up {
      color: $colorDanger;
    }
  }

  .gray-status-marker {
    background-color: $lightGrey;

    .fa-map-marker,
    .fa-caret-down,
    .fa-caret-up {
      color: $darkGrey;
    }
  }

  .tracking-event-text,
  .repeated-tracking-event-text {
    flex: 0 0 80%;
    margin-left: 10px;
    margin-bottom: 20px;
    max-width: 90%;
  }

  .collapsed-tracking-event {
    margin-bottom: 0 !important;
  }
}

/* MAP */
.map-toolbar {
  position: absolute;
  top: 15px;
  left: 15px;
  z-index: 1;

  .select {
    width: 250px !important;
    display: inline-block;
  }

  .btn {
    border-radius: 5px;
    color: black !important;
    background: white;
    border-color: $lightGrey;
    margin-left: 10px;

    &.active {
      background: white;
      border: 3px solid $primaryColor;
      color: black;
    }

    &:hover {
      svg {
        color: $primaryColor !important;
      }
    }

    svg {
      margin-right: 0;
    }
  }
}

.map {
  border: 3px solid transparent;
  position: relative;

  &.edit-active {
    border: 3px solid $colorDanger;
  }

  .status {
    position: absolute;
    top: -50px;
    right: -10px;
    width: 200px;
    text-align: right;
    z-index: 1;
  }

  .edit-button {
    z-index: 1;

    &.exit {
      background: $colorDanger;
      border-color: $colorDanger;
    }
  }

  .map-button-bar {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
  }

  .snail-trail-button {
    margin-right: 20px;
    background: white;

    &:hover {
      background: $primaryColor !important;
    }
  }
}

.map-popover {
  padding: 10px 20px;
  z-index: 1;
  background: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  max-width: 400px;

  box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15) !important;
  -webkit-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15) !important;
  -moz-box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.15) !important;
}

.map-popover-tags {
  @apply flex gap-2 my-2;
  max-width: 300px;
}

.zone-details {
  position: absolute;
  right: 10px;
  top: 60px;
  width: 300px;
}

.map-panel {
  position: absolute;
  left: 10px;
  top: 60px;
  width: 250px;
  bottom: 10px;
}

/* TAGS */
.scrollable-list-150 {
  max-height: 150px;
  overflow-y: auto;
  margin-top: 5px;
}

/* RATES */
.service-level {
  padding: 5px 0 10px;
  margin-bottom: 10px;
}

.linked-accounts {
  .ReactTable {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
}

.revision-period {
  font-size: 10pt;
  margin-bottom: 5px;
  margin-top: 0;
}

.rate-formula-type-radio {
  margin-top: 5px;
  cursor: pointer;
}

.trust-mark {
  float: right;

  img {
    height: 30px;
    margin-right: 10px;
  }

  .visa {
    height: 24px;
  }
}

.company-logo img {
  max-height: 40px;
}

.or {
  padding-top: 30px;
}

/* COMMUNICATION LOG */
.communication-log {
  background: $lighterGrey;
  border: 1px solid $lightGrey;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;

  .list-element {
    border-right: 1px solid $lightGrey;
    padding: 10px;
    cursor: pointer;

    &.active {
      background: $lightGrey !important;
    }

    &:hover {
      background: $lightestGrey;
    }

    .subject {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      font-weight: bold;
    }
  }
}

/*  ACCOUNT NOTES */
.account-notes-wrapper {
  & .logs-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  & .timeline-list {
    list-style-type: none;
    padding: 0;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 2px;
      background-color: #e5e5e5;
      top: 1em;
      bottom: 3em;
      left: 40px;
      margin-left: -3px;
    }
  }

  & .timeline-item {
    display: flex;
    padding: 7px 0;
  }

  & .checkpoint-item {
    border-radius: 50%;
    background: #fffbd3;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  & .checkpoint-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    padding: 0 7px 5px;
  }

  & .checkpoint-dates {
    display: flex;
    color: #8f8f8f;
    font-size: 13px;
  }

  & .checkpoint-content {
    & .order-log-change {
      display: flex;
      gap: 2rem;

      & .order-log-change-key {
        font-weight: bold;
      }
    }

    & p {
      margin: 0;
    }
  }

  & .day-display {
    display: flex;
    color: #8f8f8f;
    font-size: 13px;

    & p {
      margin: 0 0 0 5px;
    }
  }

  & .checkpoint-spacing {
    padding: 0px 5px;
    display: flex;
    justify-content: center;
    width: 50px;
  }

  & .order-log-user {
    margin-left: 3px;
  }

  & .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  & .no-logs-alert {
    margin-top: 1.5rem;
  }

  & .stop-fetching-text {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    & div {
      border-radius: 50%;
      border: 1px solid #3aaf46;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px;
    }

    & span:nth-child(2) {
      margin-top: 7px;
    }

    & span:last-child {
      color: #6c757d;
    }
  }
}

/*  ACCOUNT ACTIVITIES*/
.account-activity-wrapper {
  & .logs-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  & .timeline-list {
    list-style-type: none;
    padding: 0;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      width: 2px;
      background-color: #e5e5e5;
      top: 1em;
      bottom: 3em;
      left: 40px;
      margin-left: -3px;
    }
  }

  & .timeline-item {
    display: flex;
    padding: 7px 0;
  }

  & .checkpoint-item {
    border-radius: 50%;
    background: #fed7d7;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  & .checkpoint-container {
    width: 75%;
    display: flex;
    flex-direction: column;
    padding: 0 7px 5px;
  }

  & .checkpoint-dates {
    display: flex;
    color: #8f8f8f;
    font-size: 13px;
  }

  & .checkpoint-content {
    & .order-log-change {
      display: flex;
      gap: 2rem;

      & .order-log-change-key {
        font-weight: bold;
      }
    }

    & p {
      margin: 0;
    }
  }

  & .day-display {
    display: flex;
    color: #8f8f8f;
    font-size: 13px;

    & p {
      margin: 0 0 0 5px;
    }
  }

  & .checkpoint-spacing {
    padding: 0px 5px;
    display: flex;
    justify-content: center;
    width: 50px;
  }

  & .order-log-user {
    margin-left: 3px;
  }

  & .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
  }

  & .no-logs-alert {
    margin-top: 1.5rem;
  }

  & .stop-fetching-text {
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;

    & div {
      border-radius: 50%;
      border: 1px solid #3aaf46;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 3px;
    }

    & span:nth-child(2) {
      margin-top: 7px;
    }

    & span:last-child {
      color: #6c757d;
    }
  }

  & .account-activity-badge {
    display: inline;
    border-radius: 15px;
    padding: 2px 10px;
    font-weight: normal;
    overflow: hidden;
    white-space: nowrap;
    color: white;

    &.block {
      font-weight: normal;
      display: block;
    }
  }

  & .green-account-activity {
    background-color: $green;
  }

  & .fuschia-account-activity {
    background-color: #f178b6;
  }

  & .blue-account-activity {
    background-color: #007bff;
  }

  & .orange-account-activity {
    background-color: #ff6a00;
  }

  & .red-account-activity {
    background-color: #ff024e;
  }

  & .yellow-account-activity {
    background-color: #ffb600;
  }

  & .turquoise-account-activity {
    background-color: #3dc7ae;
  }
}

/* BULK ACTIONS */

.response-email-header-failed {
  background-color: rgb(255, 229, 226);

  .card-header {
    .header-text {
      margin-left: 15px;
    }

    svg {
      color: #ff024e;
    }
  }
}

#bulk_email_editor {
  & .ck-editor__editable {
    min-height: 150px !important;
  }
}

/* MEDIA QUERIES */

@media all and (max-width: 992px) {
  .show-for-small {
    display: block;
  }

  .right-hand-tools {
    margin-bottom: 10px;
    margin-top: 15px;

    .btn {
      margin-top: 0 !important;
    }
  }

  .closed {
    right: -250px;
  }
}

@media only screen and (min-width: 1024px) {
  #track-root {
    width: 100% !important;
  }
}

@media (max-width: 450px) {
  .rate {
    width: 100% !important;
  }

  .trust-mark img {
    height: 30px;
  }

  .trust-mark .visa {
    height: 25px;
  }

  .or {
    padding-top: 10px;
    text-align: center;
  }

  .image-success {
    width: 200px !important;
  }

  .block-left-if-small {
    display: block !important;
    text-align: left !important;
    margin-bottom: 0px;
    padding-top: 8px;
  }
}

.tooltip-container {
  --tooltipBackground: #666666;
  --tooltipBorder: #c0c0c0;
  --tooltipColor: #fff;

  background-color: var(--tooltipBackground);
  border-radius: 0.4rem;
  border: 1px solid var(--tooltipBorder);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  color: var(--tooltipColor);
  display: flex;
  overflow-wrap: break-word;
  flex-direction: column;
  white-space: normal;
  padding: 0.4rem;
  transition: opacity 0.3s;
  z-index: 9999;
}

.w-200 {
  width: 200px;
}

.tooltip-container[data-popper-interactive="false"] {
  pointer-events: none;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow {
  left: 0;
  margin-top: -0.4rem;
  top: 0;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow::before {
  border-color: transparent transparent var(--tooltipBorder) transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
  position: absolute;
  top: -1px;
}

.tooltip-container[data-popper-placement*="bottom"] .tooltip-arrow::after {
  border-color: transparent transparent var(--tooltipBackground) transparent;
  border-width: 0 0.5rem 0.4rem 0.5rem;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow {
  bottom: 0;
  left: 0;
  margin-bottom: -1rem;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow::before {
  border-color: var(--tooltipBorder) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
  position: absolute;
  top: 1px;
}

.tooltip-container[data-popper-placement*="top"] .tooltip-arrow::after {
  border-color: var(--tooltipBackground) transparent transparent transparent;
  border-width: 0.4rem 0.5rem 0 0.5rem;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow {
  left: 0;
  margin-left: -0.7rem;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow::before {
  border-color: transparent var(--tooltipBorder) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
}

.tooltip-container[data-popper-placement*="right"] .tooltip-arrow::after {
  border-color: transparent var(--tooltipBackground) transparent transparent;
  border-width: 0.5rem 0.4rem 0.5rem 0;
  left: 6px;
  top: 0;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow {
  margin-right: -0.7rem;
  right: 0;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow::before {
  border-color: transparent transparent transparent var(--tooltipBorder);
  border-width: 0.5rem 0 0.5rem 0.4em;
}

.tooltip-container[data-popper-placement*="left"] .tooltip-arrow::after {
  border-color: transparent transparent transparent var(--tooltipBackground);
  border-width: 0.5rem 0 0.5rem 0.4em;
  left: 3px;
  top: 0;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }

  to {
    transform: scale(1) rotate(360deg);
  }
}

.grow-animate {
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
  }
}

@import "print.scss";
